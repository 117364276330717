:root {
	--turquoise: #14f2f8;
	--yellow: #ebc11a;
	--blue2: #5c91b6;
	--blue3: #1b7895;
	--blue1: #37a1d2;
	--light: #ffffff;
	--dark: #000000;
}
/*************Tab Device starts************/
/*****************************************/
@media(max-width:1650px) and (min-width:992px) {
	.navbar.active .mobile-menu li .dropdown-wrap button {
		background: transparent !important;
		border: none;
		color: var(--yellow);
		font-size: 2.5rem !important;
		box-shadow: none !important;
		line-height: 1;
		margin-right: 2.2rem;
	}
	.navbar.active .dropdown-toggle::after {
		display: none;
	}
	.navbar.active .dropdown-wrap2 .dropdown-menu {
		top: 3.5rem !important;
		border: 0;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		min-width: 12rem;
		right: 27px;
		left: auto;
	}
	.navbar.active .dropdown-wrap2 .dropdown-menu {
		top: 0.8rem !important;
		border: 0;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		min-width: 12rem;
	}
	.navbar.active .login-dropdown-body li a {
		padding: .6rem 1rem;
		font-size: 1.6rem;
		font-weight: 600;
		text-align: center;
		color: var(--yellow);
		margin: 0;
		border-bottom: 1px solid var(--yellow);
	}
	/* .navbar.active .submenu li a {
		padding: 0 1rem;
		text-align: left;
		border-left: 0;
	} */
	.navbar.active .sticky_humburger {
		display: block;
	}
	/* .navbar.active .sticky_navmenu {
		display: none;
	} */
	.sticky_humburger {
		position: absolute;
		right: 50px;
		top: -6px;
	}
	.navbar.active .back-home-main a {
		color: var(--yellow);
	}
}
@media(max-width:1450px) and (min-width:992px){
	.navbar.active .back-home-main a:hover{
		color: var(--dark) !important
	}
	#filterHeader .sticky_humburger {
		top: 5px;
	}
	#filterHeader .navbar.active .mobile-menu li .dropdown-wrap button {
		margin-right: 3.2rem;
	}
}
@media(max-width:1199px) and (min-width:992px){
	.mobile-menu {
		display: flex;
		flex-direction: row;
		margin-right: 3.5rem;
		margin-bottom: 4.2rem;
	}
	.guestProfile {
		margin-right: 0;
	}
}
@media(max-width:1200px){
	.mobile-menu li .dropdown-wrap button {
		background: transparent !important;
		border: none;
		color: var(--yellow);
		font-size: 2.8rem;
		box-shadow: none !important;
		line-height: 1;
		margin-right: 1.2rem;
	}
	.dropdown-toggle::after{
		display: none !important;
	}
}
@media(max-width:1200px) and (min-width:841px){
	.mobile-menu li a i {
		display: none;
	}
}

@media(max-width:841px){
	.sticky-search-wrap {
		display: none !important;
	}
	.MobileSearchInner .sticky-search-wrap {
		display: block !important;
	}
}

@media(max-width:991px) {
	.pt-100 {
		padding-top: 0;
	}

	.pt-150 {
		padding-top: 10rem;
	}

	.pr-pt-150 {
		padding-top: 10px;
	}

	#MobileSearch {
		padding-top: 2rem;
		height: 100vh;
	}

	#MobileSearch .sticky-search-wrap .sticky-search-inner input {
		width: 100%;
	}

	.back-home-main a {
		color: var(--yellow);
	}

	.aboutus-section {
		padding: 0;
	}

	.shopSlider {
		padding: 0;
	}

	.shopSlider .slick-slide img {
		padding-top: 0px;
		height: 55vh;
	}

	.shopSlider .slick-dots {
		bottom: 0 !important;
	}

	.reviewsLeft {
		margin-bottom: 20px;
	}

	.sectionHeading,
	.sectionHeading span {
		margin-bottom: 1rem;
	}

	.reviewsRight {
		height: 380px;
	}

	.mobile-navbar .logo-wrap img {
		width: 15rem;
	}

	#systemHeader, #filterHeader {
		height: 56px;
		border-radius: 0 0 4rem 0;
	}

	.banner-text-inner {
		padding-left: 1rem;
		padding-top: 25rem;
	}

	.slider-text-inner {
		padding-left: 2rem;
		top: 10rem;
		padding-right: 2rem;
	}

	.scroll {
		padding-left: 1rem;
		padding-top: 25rem;
	}

	.mobile-app .slick-arrow {
		display: none !important;
	}

	.single-partner-feature {
		padding: 0;
	}
	#fix-header .navbar.active {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

@media(max-width:992px) and (min-width:768px) {
	html {
		font-size: 61%;
	}

	.navbar.mobile-navbar .container {
		max-width: 976px;
	}

	.mobile-navbar .logo-wrap img {
		/* margin-top: -35px; */
	}

	/********* Home page starts *********/
	/* Header starts */
	.navbar.mobile-navbar {
		margin-top: .5rem;
	}

	#header .navbar {
		padding-right: 0;
		padding-left: 0;
		padding-top: 1rem !important;
	}

	.slider-text-inner h1 {
		font-size: 5rem;
	}

	.tEXZF {
		top: 32px !important;
	}

	#headerSearch {
		width: 43rem;
		height: 5.2rem;
		padding: 2.2rem;
		font-size: 2rem;
	}

	.banner-search-wrap {
		position: relative;
		width: 43rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	.banerTextBottom {
		width: 43rem;
	}

	.banner-search-icon .fas.fa-search {
		top: 1.4rem;
		right: 1.8rem;
		font-size: 2.5rem;
	}

	.download-app img {
		width: 15.5rem;
	}

	.heroSlider .slick-slide img {
		height: 72vh;
	}

	.slick-dots li button::before {
		font-size: 1.2rem !important;
	}

	/* .navbar.active {
		display: none !important;
	} */

	.mobile-menu .dropdown-toggle::after {
		display: none;
	}

	.mobile-menu li a i {
		color: var(--yellow);
		font-size: 2.8rem;
		margin-top: 0.4rem;
		margin-right: 1rem;
	}

	.navbar-nav.mobile-menu li a {
		margin-right: 1.8rem;
		margin-bottom: 1.3rem;
	}

	.MobileSearchInner {
		padding: 0 0.5rem;
	}

	#MobileSearch .sticky-search-wrap {
		display: block;
	}

	#MobileSearch .MobileSearchInner .BackHome img {
		width: 3rem;
		margin-bottom: 1.5rem;
	}

	#MobileSearch .sticky-search-wrap .sticky-search-inner {
		margin-right: 0;
	}

	#MobileSearch .sticky-search-wrap .location-popup {
		width: 100%;
		top: 3.3rem;
		left: 0;
		margin-left: 0;
	}

	#MobileSearch .sticky-search-wrap .location-inner {
		text-align: center;
		margin-top: 1rem;
	}

	#MobileSearch .search-location .location-inner .ireland {
		justify-content: center;
	}

	#MobileSearch #sticky-search-collapse1 {
		z-index: 1;
	}
	.navbar.active .show>.btn-primary.dropdown-toggle{
		color: var(--blue3);	
	}
	.dropdown-wrap2 .dropdown-menu {
		top: 100% !important;
		border: 0;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		min-width: 12rem;
		right: 0 !important;
		left: auto !important;
	}

	.login-dropdown-body li a {
		padding: .6rem 1rem;
		font-size: 1.6rem;
		font-weight: 600;
		text-align: center;
		color: var(--yellow);
		margin: 0;
	}

	.login-dropdown-body li a {
		border-bottom: 1px solid var(--yellow);
	}

	/* Header ends */

	/* Service starts */
	.service-card-wrapper {
		display: flex;
		justify-content: center;
	}

	/* Service ends */

	/* Venu starts */
	#Venu {
		padding-bottom: 6rem;
	}

	#Venu .slick-slider .slick-dots {
		display: none !important;
	}

	.FeatureSlider img {
		height: 58vh;
	}
	.FeatureSlider iframe {
		height: 58vh !important;
	}

	/* Venu ends */

	/* Featured starts */
	.FeatureSlider .caption {
		width: 60%;
	}

	.FeatureSlider .caption h1 {
		font-size: 4.5rem;
	}

	.FeatureSlider .caption h1 a {
		font-size: 4.5rem;
	}

	/* Featured ends */
	/********* Home page ends *********/

	/********* Partner page starts *********/
	#partnerHeader .mobile-navbar .logo-wrap {
		display: none;
	}

	#partnerHeader .mobile-navbar .cKxlmH {
		top: 1px;
	}

	#partnerHeader .mobile-navbar .cKxlmH div {
		background: #fff;
	}

	#partnerHeader .mobile-nav ul li {
		display: none;
	}

	#partnerHeader .mobile-nav .logo img {
		width: 12rem;
	}

	/* Dashboard starts */
	#dashboard .heading {
		font-size: 3rem;
	}

	/* Dashboard ends */

	/* Features starts */
	.featuresInner .heading h2 {
		font-size: 3rem;
	}

	.featuresInner .heading h2 span {
		font-size: 3rem;
	}

	.featuresInner .heading p {
		font-size: 2rem;
	}

	.featureCard h5 {
		font-size: 2.2rem;
	}

	.featuresWrap {
		margin-top: 38rem;
	}

	.featuresInner {
		top: -34rem;
	}

	.feature-bottom {
		padding-top: 75px;
	}

	.bg-text h5 {
		font-size: 3rem;
	}

	.bg-text h2 {
		font-size: 2.5rem;
		margin-left: 0;
		margin-top: 1rem;
	}

	.footer-widget img {
		margin-left: 0;
	}
	/* Features ends */

	/********* Partner page ends *********/
}

/*************Tab Device ends************/
/****************************************/


/*************Mobile Device starts************/
/********************************************/
@media (max-width:767px) {
	html {
		font-size: 58%;
	}

	p {
		font-size: 1.4rem;
	}

	section {
		margin-bottom: 20px;
	}

	#shopAbout .sectionHeading {
		margin-bottom: 1rem;
	}
	.card {
		padding: 1.5rem;
	}
	#partnerLogin, #ForgotPass, #LoginMail, #SignUp, #businessSignUp, #editprofile {
		background: transparent;
	}

	/********* Home page starts *********/
	/* Header starts */
	.mobile-navbar .logo-wrap img {
		margin-top: 0;
	}

	.navbar {
		margin-top: 0;
	}

	#header .navbar,#fix-header .navbar{
		padding: 1rem 0 !important;
	}
	/* .navbar.active #header .navbar{
		padding: 0 !important;
	} */

	.mobile-navbar {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	/* .navbar.active {
		display: none !important;
	} */

	.mobile-menu {
		display: flex;
		flex-direction: row;
		margin-right: 3.5rem;
		margin-bottom: 0;
	}

	.mobile-menu .dropdown-toggle::after {
		display: none;
	}

	.mobile-menu li a i {
		color: var(--yellow);
		font-size: 2.5rem;
		margin-top: 0.9rem;
		margin-right: 0;
	}

	.navbar-nav.mobile-menu li a {
		margin-right: 1.8rem;
		margin-bottom: 1.3rem;
	}

.mobile-menu li .dropdown-wrap button {
	background: transparent !important;
	border: none;
	color: var(--yellow);
	font-size: 2.5rem !important;
	box-shadow: none !important;
	line-height: 1;
	margin-right: 5px;
	margin-left: 5px;
}

	.MobileSearchInner {
		padding: 0 0.5rem;
	}

	#MobileSearch .sticky-search-wrap {
		display: block;
	}

	#MobileSearch .MobileSearchInner .BackHome img {
		width: 3rem;
		margin-bottom: 1.5rem;
	}

	#MobileSearch .sticky-search-wrap .sticky-search-inner {
		margin-right: 0;
	}

	#MobileSearch .search-location .location-inner .ireland {
		justify-content: center;
	}

	#MobileSearch #sticky-search-collapse1 {
		z-index: 1;
	}

	.dropdown-wrap2 .dropdown-menu {
		top: 0.8rem !important;
		border: 0;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		min-width: 12rem;
	}
	.navbar.active .dropdown-wrap2 .dropdown-menu {
		top: 100% !important;
		right: 0 !important;
		left: auto !important;
	}
	.navbar.active .show>.btn-primary.dropdown-toggle{
		color: var(--blue1) !important;
	}

	.login-dropdown-body li a {
		padding: .6rem 1rem;
		font-size: 1.6rem;
		font-weight: 600;
		text-align: center;
		color: var(--yellow);
		margin: 0;
	}

	.edit-pr-logout button,
	.call-to-action {
		margin-top: 20px;
	}

	.call-to-action-business .action-top, .call-to-action-business .action-bottom {
		width: 300px;
	}
	.action-top .action-text {
		font-size: 25px;
	}

	.login-dropdown-body li a {
		border-bottom: 1px solid var(--yellow);
	}

	.slider-text-inner h1 {
		font-size: 2.8rem;
		margin-bottom: 0;
		width: auto;
	}

	.banerTextBottom {
		width: 100%;
	}

	.heroSlider .slick-slide img {
		height: 400px;
	}

	.heroSlider .slick-slide img {
		object-position: center right;
	}

	.banner-search-wrap {
		margin: 0;
		margin-top: 0 !important;
		width: 96vw;
	}
	#headerSearch {
		width: 96vw;
	}
	.download-app {
		margin-bottom: 3.5rem;
	}

	.download-app a:first-child {
		display: block;
	}

	.slick-dots li button::before {
		font-size: 1rem !important;
		color: #ddd !important;
	}
	.slick-dots li.slick-active button::before {
		color: var(--yellow) !important;
	}

	.slider-text-inner {
		top: 0;
		/* background: rgba(0,0,0,0.4); */
		height: 100%;
		display: flex;
		align-items: center;
		border-radius: 0 0 5rem;
		width: 100%;
	}
	/* Header ends */

	/* download app */
	.app_section {
		padding-top: 35px;
		padding-bottom: 35px;
		margin-bottom: 0;
	}
	.app_download_img img {
		transform: none;
	}
	.app_download_img {
		text-align: center;
	}

	/* Services starts */
	.row.serviceItem .card {
		width: 98% !important;
	}

	.serviceItem .card-title.h5 {
		font-size: 1.8rem;
		margin-bottom: 2rem;
	}

	.service-info {
		text-align: center;
		margin-bottom: 30px;
	}

	.serviceItem .slick-arrow {
		display: none !important;
	}

	.service-info h4 {
		font-size: 1.4rem;
	}

	.service-info h2 {
		font-size: 1.5rem;
		width: 100%;
	}

	.service-info p {
		width: 100%;
	}

	#service::after {
		height: 9.5rem;
	}

	.service-info a {
		margin-left: 0;
	}

	.service-card-wrapper .card {
		margin: 2rem auto;
	}

	#searchResult .vItemWrap,
	#recomVenues .vItemWrap {
		height: 420px;
	}
	.service_content{
		overflow-x: auto;
		line-break: auto;
	}
	.service_content_row {
		width: 100%;
		justify-content: space-between;
		overflow: hidden;
	}
	/* Services ends */

	/* Venu starts */
	#Venu {
		background: #F3F2F3;
		padding-top: 5rem;
		padding-bottom: 7rem;
	}

	.venu-heading h2 {
		font-size: 2.5rem;
		margin-bottom: 3.5rem;
		text-align: center;
		padding-left: 0;
	}

	.ratingWrap .icon .fa-star {
		font-size: 1.8rem;
	}

	.venuItemInfo .venuTtemName span {
		font-size: 2rem;
	}

	.venuItem .venuBtn {
		font-size: 1.5rem;
		border-radius: .5rem;
	}

	/* Venu ends */

	/* Feature starts */
	.slick-prev::before,
	.slick-next::before {
		font-size: 3.5rem !important;
	}

	.FeatureSlider img {
		width: 100% !important;
		height: 50vh;
		object-fit: cover;
	}
	.FeatureSlider iframe {
		width: 100% !important;
		height: 50vh !important;

	}

	.FeatureSlider .caption {
		width: 80%;
	}

	.FeatureSlider .caption h1 {
		font-size: 2.5rem;
	}

	.FeatureSlider .caption h1 a {
		font-size: 2.5rem;
		padding-bottom: 0.5rem;
	}

	/* Feature ends */

	/* Testimonial starts */
	#Testimonial {
		padding: 3rem 0;
		margin-bottom: 10px;
	}
	.testimonialItem .card img {
		margin-bottom: 2rem;
	}
	.testimonialItem .card-title.h5 {
		font-size: 2rem;
		padding-left: 1rem;
	}
	.testimonialCard .card-text {
		height: 140px;
	}
	.testimonialCard span {
		font-size: 1.5rem;
		padding-left: 1rem;
	}

	#Testimonial .venu-heading h2 {
		padding-left: 0;
	}

	.testimonialCard {
		width: 100%;
	}
	/* Tastimonial ends */

	/* Footer starts */
	.footer-widget {
		margin-bottom: 1.6rem;
	}
	.footer-widget img {
		width: 11rem;
	}
	.footer-widget img {
		margin-left: 0;
	}
	.footer-social {
		margin-bottom: 1.5rem !important;
	}
	.footer-link li {
		padding-top: 0;
	}
	.footer-social ul li a {
		width: 3.5rem;
		height: 3.5rem;
		line-height: 3.4rem;
		font-size: 1.8rem;
	}
	/* Footer ends */
	/********* Home page ends *********/

	/* Blog page */
	.blog-card .card-img-top {
		height: auto;
	}
	.blog-card .outer-card {
		height: auto;
	}
	.blog-card h2 {
		font-size: 22px;
	}
	.call-to-action .action-top, .call-to-action .action-bottom {
		width: 300px;
	}

	/********* Login page starts *********/
	/* Customerlogin starts */
	#Login .LogoWrap .Logo img {
		width: 10.5rem;
	}

	#Login .LogoWrap .BackHome img {
		width: 3rem;
	}

	.LoginWrap {
		height: auto;
	}

	.LoginWrap .Overlay {
		height: auto;
		padding: 0 2rem;
	}

	.LoginInner {
		padding: 4rem 0;
	}

	.LoginTop,
	.LoginBottom {
		padding: 2.4rem 2.5rem;
	}

	/* Customerlogin starts */

	/* Partnerlogin starts */
	.mailLoginWrap .LoginInner {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.mailLoginWrap .LoginTop {
		padding: 0;
		box-shadow: none;
	}
	/* Partnerlogin ends */

	/* Signup starts */
	#SignUp .LoginTop {
		padding: 2.4rem 1.5rem;
	}
	.mailLoginWrap .LoginBottom {
		padding: 2.4rem 3rem;
	}
	.sectionHeading, .sectionHeading span {
		font-size: 3rem;
	}
	.resultItem h2 {
		padding-left: 0;
		padding-right: 0;
	}

	/* Signup ends */
	/********* Login page endts *********/

	/********* Partner page starts *********/
	#partnerHeader .mobile-navbar .logo-wrap {
		display: none;
	}

	#partnerHeader .mobile-navbar .cKxlmH {
		top: 1px;
	}

	#partnerHeader .mobile-navbar .cKxlmH div {
		background: #fff;
	}

	#partnerHeader .mobile-nav ul li {
		display: none;
	}

	#partnerHeader .mobile-nav .logo img {
		width: 12rem;
	}

	/* Dashboard starts */
	#dashboard .heading {
		font-size: 2.5rem;
		padding-left: 0;
	}

	/* Dashboard ends */

	/* Features starts */
	.featuresInner {
		top: -721px;
	}

	.featuresWrap {
		margin-top: 1180px;
		border-radius: 10rem 0 40rem 40rem;
	}

	.featuresInner .heading h2 {
		font-size: 2.5rem;
	}

	.featuresInner .heading p {
		font-size: 1.6rem;
	}

	.featuresInner .heading h2 span {
		font-size: 2.5rem;
	}

	.featureCard {
		height: auto;
	}

	.bg-text {
		width: 100%;
		padding: 2rem;
		top: 0;
		padding-top: 4rem;
		padding-left: 4rem;
	}

	.bg-text h5 {
		padding-right: 0;
		text-align: left;
		font-size: 2.5rem;
	}

	.bg-text h2 {
		margin-left: 0;
		font-size: 2.3rem;
		margin-top: 2rem;
	}

	.bg-man {
		margin: 0 auto;
	}

	.feature-bottom {
		padding-top: 0;
	}

	.feature-bottom-row {
		flex-direction: column-reverse;
	}

	/* Features ends */
	/********* Partner page ends *********/

	/********* Services page starts *********/
	#filterHeader .container-fluid {
		padding: 0 3% !important;
	}
	#filterHeader .mobile-navbar .cKxlmH {
		top: 1px;
	}

	#filterHeader .mobile-navbar .cKxlmH div {
		background: #fff;
	}

	#filterHeader .mobile-nav ul li {
		display: none;
	}

	#filterHeader .mobile-nav .logo img {
		width: 12rem;
	}

	.banner-text-inner,
	.scroll {
		padding-top: 18rem;
	}
	/********* Services page ends *********/
	.nav_tab_wrapper {
		height: 250px;
		margin-bottom: 30px;
	}
	.home_page_video iframe {
		height: 204px !important;
		width: 364px !important;
	}
	.service_content_row {
		margin-bottom: 7px;
	}

	.SubCategory {
		padding: 0 !important;
	}

	.SubCategory .name {
		align-items: flex-start;
	}
		.guestProfile {
			margin-right: 0;
		}
}

/*************Mobile Device ends************/
/******************************************/
@media (max-width:576px) {
	.apt-right {
		margin-top: 10px !important;
	}

	.custom-datepicker {
		width: 300px;
	}

	.react-datepicker {
		font-size: 12px !important;
		width: 220px;
	}

	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 3rem !important;
		line-height: 3rem !important;
	}

	.react-datepicker__current-month,
	.react-datepicker-time__header,
	.react-datepicker-year-header {
		font-size: 14px !important;
	}

	.chuzy-time {
		width: 80px !important;
	}

	.react-datepicker__month {
		text-align: left !important;
	}

	.available-time {
		width: 80px;
		height: 180px;
		padding: 2px;
	}

	.modal-body .form-check-input,
	.modal-body .form-check-input:checked[type="radio"] {
		height: 12px;
		width: 12px;
	}

	.available-time label {
		font-size: 12px;
	}

	.react-datepicker__month {
		margin: 0 !important;
	}

	.react-datepicker__header {
		width: 215px;
	}
	/* faq */
	.faq-static-section{
		text-align: center;
	}
	.faq-search-inner {
		position: relative;
		/* margin-right: 1rem;
		margin-left: 1rem; */
		width: 100%;
	}
	.faq-search-inner .fa-search {
		position: absolute;
		top: 1rem;
		left: 2rem;
		cursor: pointer;
		font-size: 2.2rem;
		color: gray;
	}
	.faq-search-inner input {
		width: 100%;
		padding: 1rem;
		padding-left: 4.8rem;
		border: .1rem solid #80808042;
		border-radius: 25px;
		font-size: 14px;
		box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.1);
	}
	.comparison_graph {
		height: 636px !important;
		padding: 0 123px !important;
	}
	.comparison_graph .text-nowrap{
		white-space: wrap !important;
	}
	.mdl_bar{
		white-space: nowrap !important;
	}
}

@media (max-width:414px) {
	.category h5 {
		font-size: 1.5rem;
	}
}